const title = 'Онлайн-школа Синергия';
const description = 'Домашняя онлайн-школа Синергия приглашает учеников со всей страны на '
  + 'дистанционное семейное обучение по школьным программам 7-11 классов и подготовку к ЕГЭ/ОГЭ';
const image = '/img/head_preview.jpg';

export default {
  head() {
    if ((this.onlyRoute && this.onlyRoute !== this.$route.name) === true) {
      return null;
    }

    let path = this.$route.fullPath;
    if (path === '/') {
      path = '';
    }

    return {
      title: this.title || title,
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.title || title,
        },
        {
          hid: 'description',
          name: 'description',
          content: this.description || description,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.description || description,
        },

        {
          hid: 'og:image',
          name: 'og:image',
          content: this.image || image,
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          hid: 'og:image:alt',
          name: 'og:image:alt',
          property: 'og:image:alt',
          content: this.image || image,
        },
        {
          hid: 'fb:image:url',
          name: 'fb:image:url',
          property: 'fb:image:url',
          content: this.image || image,
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          property: 'twitter:image',
          content: this.image || image,
        },
        {
          hid: 'og:image:type ',
          name: 'og:image:type ',
          property: 'og:image:type ',
          content: 'image/jpg',
        },
      ],

      link: [
        ...this.canonicalHref && this.alternateHref ? [
          { rel: 'canonical', href: this.canonicalHref },
          { rel: 'alternate', href: this.alternateHref },
        ] : [],
        ...this.canonical ? [{ rel: 'canonical', href: `https://school.synergy.ru${path}` }] : [],
        ...(this.headLinks || []),
      ],

      script: this.headScripts || [],
    };
  },
};
